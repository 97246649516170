import { createSlice } from "@reduxjs/toolkit";

import DashbordThunk from "../actions/dashboard";

export interface dashState {
  data: {
    balance:any,
    api_key:any,
    sent:any
  }
  loading: boolean;

  error: boolean;
  errorMessage: string | undefined;
}

export const initialState: dashState = {
  data: {
    balance:null,
    api_key:null,
    sent:null
  },

  loading: false,

  error: false,
  errorMessage: undefined,
};

export const dashSlice = createSlice({
  name: "Dash data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DashbordThunk.pending, (state) => {
        
        state.loading = true;
        state.error = false;
      })
      .addCase(DashbordThunk.rejected, (state) => {

        state.loading = false;
        state.error = true;
      })
      .addCase(DashbordThunk.fulfilled, (state, { payload }) => {

        state.loading = false;
        if (payload.error) {
  
          state.error = true;
          state.errorMessage =
            payload?.error?.response?.data?.message || "error";
        } else {
          state.error = false;
          state.data = payload;
       
        }
      });
  },
});

export default dashSlice;
