import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const PostSenderIdThunk = createAsyncThunk(
  'senderId/add',
  async (data:any,  { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/api/v1/sender-ids',
     data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
          },
        }
      );
      return response.data;
    }catch (error:any) {
        return rejectWithValue(error?.response?.data?.message);
      }
  }
);
export default PostSenderIdThunk;
