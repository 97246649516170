import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const UsersThunk = createAsyncThunk('client', async (querry:any) => {
  try {

    const res = await axios.get(`/api/v1/users?${querry}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
      },
    });
    console.log(res.data)
    return res.data;

  } catch (error) {

    return { error };
  }
});

export default UsersThunk;
