import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const DashbordThunk = createAsyncThunk('Dashboard', async () => {
  try {

    const res = await axios.get('/api/v1/wallet/dasboard', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
      },
    });

    return res.data;
  } catch (error) {

    return { error };
  }
});

export default DashbordThunk;
