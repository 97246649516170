import * as yup from "yup";

const userSchema = yup.object().shape({

  firstName: yup.string().required("firstName is required"),
  lastName: yup.string().required("lastName is required"),

});

export default userSchema;
