import { createSlice } from "@reduxjs/toolkit";
import UserThunk  from "../actions/user"; 
import UpdateProfile from "../actions/editUser";

export interface UserState {
  user: {
    firstName:any,
    lastName:any,
    role:any,
    email:any,
    created_at:any
    id:any
  }
  loading: boolean;
  error: boolean;
  errorMessage: string | undefined;
}

export const initialState: UserState = {
  user: {
    firstName: null,
    lastName: null,
    role: null,
    email: null,
    created_at: null,
    id:null
  },
  loading: false,
  error: false,
  errorMessage: undefined,
};

export const UserSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UserThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(UserThunk.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(UserThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.error = true;
          state.errorMessage = payload?.error?.response?.data?.message || "error";
        } else {
          state.error = false;
          state.user = payload;
        }
      })
      
  },
});

export default UserSlice.reducer;
