import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const senderIDThunk = createAsyncThunk('senderID', async () => {
  try {

    const res = await axios.get('/api/v1/sender-ids', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
      },
    });
  
    return res.data;
  } catch (error) {
    return { error };
  }
});

export default senderIDThunk;
