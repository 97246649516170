import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const DeleteSenderIdThunk = createAsyncThunk(
  'SenderId/delete',
  async (id:any,  { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/v1/sender-ids/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
          },
        }
      );
      return response.data;
    } catch (error:any) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);
export default DeleteSenderIdThunk;
