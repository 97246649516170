import { createSlice } from "@reduxjs/toolkit";

import ReportThunk from "../actions/messageReports";

export interface reportState {
  data: {
    list: any[]; 
    total: number | null;
    previousPage: number | null;
    nextPage: number | null;
    lastPage: number | null;
    currentPage: number | null;
  };
  loading: boolean;
  error: boolean;
  errorMessage: string | undefined;
}

export const initialState: reportState = {
  data: {
    list: [],
    total: null,
    previousPage: null,
    nextPage: null,
    lastPage: null,
    currentPage: null,
  },
  loading: false,
  error: false,
  errorMessage: undefined,
};

export const ReportSlice = createSlice({
  name: "report  data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ReportThunk.pending, (state) => {
    
        state.loading = true;
        state.error = false;
      })
      .addCase(ReportThunk.rejected, (state) => {
 
        state.loading = false;
        state.error = true;
      })
      .addCase(ReportThunk.fulfilled, (state, { payload }) => {
   
        state.loading = false;
        if (payload.error) {
        
          state.error = true;
          state.errorMessage =
            payload?.error?.response?.data?.message || "error";
        } else {
           
          state.error = false;
          state.data = payload;
   
        }
      });
  },
});

export default ReportSlice.reducer;
