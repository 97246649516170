import { createSlice } from "@reduxjs/toolkit";
import senderIDThunk from "../actions/GetSenderIds";
import PostSenderIdThunk from "../actions/PostSenderId";
import EditSenderIdThunk from "../actions/editSenderId";

export interface SenderIdState {
  data: any[]; // Use a more specific type if possible
  loading: boolean;
  error: boolean;
  errorMessage: string | undefined;
}

export const initialState: SenderIdState = {
  data: [],
  loading: false,
  error: false,
  errorMessage: undefined,
};

export const senderIdSlice = createSlice({
  name: "Api data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(senderIDThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(senderIDThunk.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(senderIDThunk.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.error = true;
          state.errorMessage =
            payload?.error?.response?.data?.message || "error";
        } else {
          state.error = false;
          state.data = payload;
        }
      })
      .addCase(PostSenderIdThunk.fulfilled, (state, { payload }) => {
        state.data = [payload, ...state.data];
      })
      .addCase(EditSenderIdThunk.fulfilled, (state, { payload }) => {
    
        const updatedRecordIndex = state.data.findIndex(
        (record) => record.id === payload.id
        );
      
        if (updatedRecordIndex !== -1) {
          state.data[updatedRecordIndex] = payload;
        }
      });
  },
});

export default senderIdSlice;
