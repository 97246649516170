import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const editUserThunk = createAsyncThunk(
  'User/edit',
  async (all:any,{ rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `api/v1/users/${all.id}`,
        all.datas,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
          },
        }
      );

      return response.data;


    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export default editUserThunk;
