import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../configs/config";

const DeleteUserThunk = createAsyncThunk(
  "users/delete",
  async (id: any, { rejectWithValue }) => {
    try {
      console.log(id);
      const response = await axios.delete(`/api/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Ishema-token")}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);
export default DeleteUserThunk;
